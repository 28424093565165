.App {
  text-align: center;
  
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.rainbow-spectrum {
    animation: rainbow 10s linear;
    animation-iteration-count: infinite;
}

@keyframes rainbow {
    100%,0%{
        color: rgb(255,0,0);
    }
    8%{
        color: rgb(255,127,0);
    }
    16%{
        color: rgb(255,255,0);
    }
    25%{
        color: rgb(127,255,0);
    }
    33%{
        color: rgb(0,255,0);
    }
    41%{
        color: rgb(0,255,127);
    }
    50%{
        color: rgb(0,255,255);
    }
    58%{
        color: rgb(0,127,255);
    }
    66%{
        color: rgb(0,0,255);
    }
    75%{
        color: rgb(127,0,255);
    }
    83%{
        color: rgb(255,0,255);
    }
    91%{
        color: rgb(255,0,127);
    }
}

.pulsate {
    animation: pulse 3s linear;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    100%,0% {
        scale: 1;
    }

    50% {
        scale: 2;
    }
}